import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Top } from "../pages/top";
export const Pages = () => {
  return (
    <>
  <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K9GFN99V"
  height="0" width="0" style={{display: "none", visibility:"hidden"}}></iframe></noscript>
    <Router>
      <Routes>
        <Route path="*" element={<Top />} />
      </Routes>
    </Router>
    </>
  )
}